import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from 'gatsby'

import { Seo as SEO } from '../../components/Seo'
import Layout from '../../components/layout/Layout'
import Keywords from "../../components/Keywords"

const PageBanner = React.lazy(() => import('../../components/common/PageBanner'))
const DescSection = React.lazy(() => import('../../components/productsAndServices/DescSection'))
const ProductSummarySection = React.lazy(() => import('../../components/productsAndServices/ProductSummarySection'))

const ProductsAndServicesPage = ({data}) => {
  const { t } = useTranslation()

  const links = [
    {label: "Home", url: "/"},
    {label: "Products and Services", url: "/products-and-services"},
  ]

  const summaryContent = data.summaries.nodes

    return(
        <Layout pathname={"/products-and-services"}>
          <PageBanner title={t('Products and Services')} links={links} bannerImage={data.banner.gatsbyImageData}/>
          <DescSection title={t('Manufacturing')} description1={t('Manufacturing Text1')} description2={t('Manufacturing Text2')}
          />
          <ProductSummarySection summaries={summaryContent}/>
          <Keywords 
            id={"product-keywords"} 
            keywords={[
              "stem cell indonesia",
              "stem cell regenic",
              "cell therapy indonesia",
              "terapi sel punca indonesia",
              "secretome indonesia",
              "terapi secretome",
              "stem cell kalbe",
              "terapi regeneratif",
              "regenerative medicine indonesia",
              "exosome",
              "iPSC",
              "terapi pengobatan regeneratif"
            ]}
          />
        </Layout>
    )
}

export default ProductsAndServicesPage

export const Head = () => {
    return (
      <SEO
        title={'Bifarma Adiluhung | Products and Services'}
        description={
          'Discover Bifarma Adiluhung\'s comprehensive range of clinically proven and quality-guaranteed biopharmaceutical generics. Explore our innovative products and services for improved health outcomes.'
        }
        keywords={[
          "stem cell indonesia",
          "stem cell regenic",
          "cell therapy indonesia",
          "terapi sel punca indonesia",
          "secretome indonesia",
          "terapi secretome",
          "stem cell kalbe",
          "terapi regeneratif",
          "regenerative medicine indonesia",
          "exosome",
          "iPSC",
          "terapi pengobatan regeneratif"
        ]}
      />
    )
}

export const query = graphql`
  query ($language: String!, $locale: String) {
    locales: allLocale(filter: {ns: {in: ["common", "products"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    banner: contentfulAsset(title: {eq: "Product Services Banner"}) {
      gatsbyImageData
    }
    summaries: allContentfulProductsServices(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        image {
          gatsbyImageData(width: 400, height:300)
        }
        title
        description {
          description
        }
        slug
      }
    }
  }
`